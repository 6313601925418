import React from "react";
import styled, { ThemeProvider } from "styled-components";
import theme from "theme";
import i18n from "i18next";
import { connect } from "react-redux";
import { hashParams } from "routes";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  cellType,
  PercentageCellData
} from "components/grid/GridDataModel";
import {
  getUuid,
  accountLinkingService,
  getCustodianLastUpdateDetails,
  tickerTypes,
  expandHoldingsForCustodian,
  isCryptoLinkingService,
  updateCustodianHolding,
  showToastTip,
  ApiClient,
  fetchTickerDetails,
  accountLinkingContainers,
  refreshCustodian,
  fetchCustodianDetails,
  irrTypes,
  getExchangeRate,
  isAppInViewMode,
  getOwnershipValueForCustodian,
  getTickerUsingId,
  withRouter,
  custodianSelector,
  store,
  getValueOfPropFromStringifiedJSON,
  costType
} from "@kubera/common";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { category } from "components/dashboard/DashboardComponentExports";
import GlowingBadgeTip from "components/tooltip/GlowingBadgeTip";
import CurrencyLabel from "components/labels/CurrencyLabel";
import CustodianDetailsComponentExports, {
  detailsTabs
} from "components/custodian_details/CustodianDetailsComponentExports";
import PercentageLabel from "components/labels/PercentageLabel";
import { ReactComponent as PoweredByCartaLogo } from "assets/images/poweredbycarta.svg";
import { ReactComponent as DownloadIcon } from "assets/images/download_icon.svg";
import { downloadFile } from "utilities/FileUtils";
import Loader from "components/loader/Loader";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EmptyHoldingsMessage = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: black;
  white-space: pre;

  a {
    color: ${props => props.theme.linkColor};
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2px;
`;

const LastUpdated = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${props => (props.isStale === true ? "rgba(255, 0, 0)" : "rgba(0, 0, 0, 0.4)")};
`;

const HoldingsBadge = styled(GlowingBadgeTip)`
  margin-right: 5px;
`;

const ExpandHoldingsButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: 4px;
`;

const IrrSummaryCell = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
  cursor: pointer;
`;

const IrrCellLabel = styled.div`
  display: flex;
  margin-left: ${props => (props.applyMargin === true ? "5px" : "")};
  color: ${props => props.theme.irrCellLabelCLR};
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const IrrCellValue = styled(CurrencyLabel)`
  display: flex;
  color: ${props => props.theme.irrCellValueCLR};
  margin-left: 5px;
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const FooterCostLabel = styled(IrrCellLabel)`
  color: white;
  font-style: normal;
`;

const FooterCostCell = styled(IrrSummaryCell)`
  margin-right: -5px;
  cursor: text;
`;

const FooterCostValue = styled(IrrCellValue)`
  color: white;
  margin-right: 13px;
  font-style: normal;
`;

const FooterPercentageChange = styled(PercentageLabel)`
  min-width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PoweredByCartaLogoComponent = styled(PoweredByCartaLogo)`
  width: 130px;
  margin: 10px 5px 3px;
`;

const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  z-index: 100;
`;

const DownloadButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const CSVDownloadIcon = styled(DownloadIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const DownloadLoaderContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class CustodianHoldingsComponent extends React.Component {
  constructor(props) {
    super(props);

    const holdings = props.holdings;
    this.gridSectionRef = React.createRef();
    const gridData = this.getGridData(props.currency, holdings);
    this.state = { gridData: gridData, isExpanding: false, showConfirmationDialog: false };

    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleExpandHoldings = this.handleExpandHoldings.bind(this);
    this.handleConfirmationDialogPositiveButtonClick = this.handleConfirmationDialogPositiveButtonClick.bind(this);
    this.handleConfirmationDialogNegativeButtonClick = this.handleConfirmationDialogNegativeButtonClick.bind(this);
    this.handleCellInvalidTickerAdded = this.handleCellInvalidTickerAdded.bind(this);

    this.updateHoldingsPromiseList = {};
  }

  componentDidMount() {
    const { refreshCustodian, fetchDetails, custodianId } = this.props;
    if (!this.props.isSameAsHoldingsTotal && this.props.linkType !== accountLinkingService.IN_HOUSE_OAUTH) {
      refreshCustodian(
        custodianId,
        () => {
          fetchDetails(custodianId);
        },
        undefined,
        undefined,
        undefined,
        { force: 1 }
      );
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.holdings !== oldProps.holdings) {
      this.setState({
        gridData: this.getGridData(this.props.currency, this.props.holdings)
      });
    }
  }

  componentWillUnmount() {
    const { refreshCustodian, fetchDetails, custodianId } = this.props;
    const promises = Object.values(this.updateHoldingsPromiseList);

    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        refreshCustodian(
          custodianId,
          () => {
            fetchDetails(custodianId);
          },
          undefined,
          undefined,
          undefined,
          { force: 1 }
        );
      });
    }
  }

  getGridData(currency, holdings) {
    var rows = [];
    let showCost = true;
    for (const [index, holding] of holdings.entries()) {
      const custodian = custodianSelector(store.getState(), holding.id);
      if (!custodian) {
        continue;
      }
      const currentRow = this.getEmptyRow(`${index + 2}`, isAppInViewMode());
      currentRow.id = holding.id;
      currentRow.isLinked =
        (this.props.accountSubscriptionIsActive || isAppInViewMode()) && !holding.linkType === false;
      currentRow.isVerified = holding.isVerified === 1 && this.props.siteConfig.showVerified === "show";
      currentRow.linkType = holding.linkType;
      currentRow.linkedHoldingsCount = holding.holdingsCount;
      currentRow.linkStatus = holding.status;
      currentRow.linkStatusInfo = holding.statusInfo;
      currentRow.linkContainer = holding.linkContainer;
      currentRow.linkProviderName = holding.linkProviderName;
      currentRow.linkAccountName = holding.linkAccountName;
      currentRow.linkAccountMask = holding.linkAccountMask;
      currentRow.isLinking = this.props.accountSubscriptionIsActive && holding.isLinking;
      currentRow.linkingFailed = holding.linkingFailed;
      currentRow.linkingAccountsData = holding.linkingAccountsData;
      currentRow.valueTickerId = holding.valueTickerId;
      currentRow.isInvestable = this.props.isReadOnlyWlClient || (holding.type === 0 || holding.type === 2);
      currentRow.isManaged = this.props.isReadOnlyWlClient || !holding.aum === false;
      currentRow.linkAccountId = holding.linkAccountId;
      currentRow.tsStart = holding.tsStart;
      currentRow.linkProviderAccountId = holding.linkProviderAccountId;
      currentRow.availableCredit = holding.availableCredit;
      currentRow.irrType = holding.irrType;
      currentRow.irr = holding.irr;
      currentRow.actualValue = holding.value;
      currentRow.linkProviderId = holding.linkProviderId;

      currentRow.cells[0].value = holding.name;

      currentRow.cells[0].description = holding.description;
      currentRow.cells[0].url = holding.url;
      currentRow.cells[0].linkType = holding.linkType;
      currentRow.cells[0].subType = holding.subType;

      currentRow.cells[2].value = holding.value;
      currentRow.cells[2].ownership = this.props.getOwnershipValueForCustodian(holding.id);
      currentRow.cells[2].invalidInputText = holding.valueInvalidInputText;
      currentRow.cells[2].tickerInfo = holding.valueTickerInfo;
      currentRow.cells[2].isEditable =
        [
          accountLinkingService.ZERION,
          accountLinkingService.IN_HOUSE_CRYPTO_OAUTH,
          accountLinkingService.IN_HOUSE_CRYPTO_API
        ].includes(holding.linkType) && holding.linkContainer === accountLinkingContainers.NFT;

      if (holding.linkType === accountLinkingService.ZILLOW) {
        currentRow.cells[2].loading = holding.isInitialRefresh;
      } else {
        currentRow.cells[2].loading = holding.isLinking === true || holding.fetchingValueTickerInfo === true;
      }

      currentRow.cells[2].pastValueDetails = holding.pastValue;
      currentRow.cells[2].canShowDayChange = currentRow.linkType !== accountLinkingService.ZILLOW;

      if (holding.valueTickerId) {
        currentRow.cells[0].currency = this.props.getTickerUsingId(holding.valueTickerId).shortName;
        currentRow.cells[2].currency = this.props.getTickerUsingId(holding.valueTickerId).shortName;
      }
      if (holding.valueExchangeRate) {
        currentRow.cells[2].exchangeRateDetails = holding.valueExchangeRate;
      }
      if (holding.rate) {
        const rateParsed = JSON.parse(holding.rate);
        currentRow.cells[2].useRateFromExchangeRateDetails = true;
        currentRow.cells[2].exchangeRateDetails = `{"date":"2024-05-09","tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
      }

      if (holding.costTickerId) {
        currentRow.cells[2].costTickerId = holding.costTickerId;
      }
      if (holding.costExchangeRate) {
        currentRow.cells[2].costExchangeRate = holding.costExchangeRate;
      }
      if (
        !(
          holding.irrType === irrTypes.COSTBASIS &&
          holding.costType === costType.AUTO &&
          currentRow.cells[2].ownership !== 100
        )
      ) {
        currentRow.cells[2].cost = holding.cost;
        if (this.props.irrType === irrTypes.HOLDING) {
          currentRow.cells[1].value = currentRow.getIrr();
        }
      }

      if (
        currentRow.isComplete() === true &&
        (currentRow.irrType === irrTypes.CASHFLOW &&
          getValueOfPropFromStringifiedJSON(currentRow.irr, "error") === `""`)
      ) {
        showCost = false;
      }

      rows.push(currentRow);
    }

    if (!this.gridSectionRef.current) {
      this.gridSectionRef.current = this.getEmptySection(0, "1");
    }
    const section = this.gridSectionRef.current;
    section.irr = this.props.irr;
    section.showCost = showCost;
    section.rows = rows;
    if (this.state?.gridData?.sheets) {
      section.columnSortKey = this.state?.gridData?.sheets[0].sections[0].columnSortKey;
      section.columnSortOrder = this.state?.gridData?.sheets[0].sections[0].columnSortOrder;
    }

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection = (forIndex, sortKey, isViewOnlyMode = false) => {
    const nameColumn = new GridColumnData("Asset", true, isViewOnlyMode === false, false);
    const irrColumn = new GridColumnData("IRR", true, false, false);
    irrColumn.hideTitleIfEmpty = true;
    irrColumn.getFooterAccessoryView = (sheetIndex, sectionIndex, columnIndex) => {
      const section = this.state.gridData.sheets[sheetIndex].sections[sectionIndex];

      const irrDetails = section.getIrrDetails();
      if (irrDetails && this.props.irrType === irrTypes.HOLDING) {
        const cashTicker = this.props.getTickerUsingId(irrDetails.all.cashTickerId);
        const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.currency);
        const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
        const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

        if (!totalCashIn === true && !totalCashOut === true) {
          return null;
        }
        return (
          <FooterCostCell>
            {!totalCashIn === false && (
              <>
                <FooterCostLabel>{section.showCost ? i18n.t("cost") : i18n.t("in")}</FooterCostLabel>
                <FooterCostValue
                  value={totalCashIn}
                  currency={this.props.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            {!totalCashOut === false && (
              <>
                <FooterCostLabel>{i18n.t("out")}</FooterCostLabel>
                <FooterCostValue
                  value={totalCashOut}
                  currency={this.props.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            <FooterPercentageChange
              value={
                irrDetails.all.value > -1 && irrDetails.all.value < 1
                  ? irrDetails.all.value.toFixed(2)
                  : Math.kuberaFloor(irrDetails.all.value)
              }
              darkBackground={true}
            />
          </FooterCostCell>
        );
      }

      return null;
    };

    const valueColumn = new GridColumnData("Value", true, isViewOnlyMode === false, false);
    const detailsColumn = new GridColumnData(null, true, false, true);
    const padCell = new GridCellData(cellType.PADDING, "", null);
    padCell.getFooterAccessoryView = () => null;

    var columns = [nameColumn, irrColumn, valueColumn, detailsColumn];
    if (this.props.irrType !== irrTypes.HOLDING) {
      columns = [nameColumn, padCell, valueColumn, detailsColumn];
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      columns,
      undefined,
      2,
      false
    );
    sectionData.getContextMenuItems = section => {
      return null;
    };
    sectionData.getFooterContextMenuItems = () => {
      return null;
    };
    sectionData.showAddNewInFooter = false;
    return sectionData;
  };

  getEmptyRow(sortKey, isViewOnlyMode = false) {
    const detailsCell = new GridCellData(cellType.DETAILS, "", null);
    detailsCell.showHint = false;
    detailsCell.toolTip = i18n.t("gridCell.detailsButtonToolTip");

    const nameCell = new GridCellData(cellType.TEXT, category.ASSET, null);
    const irrCell = new PercentageCellData(cellType.PERCENTAGE, "IRR", null, undefined, undefined);
    irrCell.onClick = (e, sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      this.handleIrrCellClick(e, sheetIndex, sectionIndex, rowIndex);
    };
    irrCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
      const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

      if (this.props.irrType !== irrTypes.HOLDING) {
        return null;
      }

      const irrDetails = row.getIrrDetails();
      if (!irrDetails === true) {
        return null;
      }

      const cashTicker = this.props.getTickerUsingId(irrDetails.all.cashTickerId);
      const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.currency);
      const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
      const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

      if (row.irrType === irrTypes.COSTBASIS) {
        if (!totalCashIn === true) {
          return null;
        }
        return (
          <IrrSummaryCell>
            <IrrCellLabel updated={row.isUpdated}>{i18n.t("cost")}</IrrCellLabel>
            <IrrCellValue
              updated={row.isUpdated}
              value={totalCashIn}
              currency={this.props.currency}
              roundDown={true}
              maxLongFormatValue={999999}
            />
          </IrrSummaryCell>
        );
      } else if (row.irrType === irrTypes.CASHFLOW) {
        if (!totalCashIn === true && !totalCashOut === true) {
          return null;
        }
        return (
          <IrrSummaryCell>
            {!totalCashIn === false && (
              <>
                <IrrCellLabel updated={row.isUpdated}>{i18n.t("in")}</IrrCellLabel>
                <IrrCellValue
                  updated={row.isUpdated}
                  value={totalCashIn}
                  currency={this.props.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
            {!totalCashOut === false && (
              <>
                <IrrCellLabel updated={row.isUpdated} applyMargin={!totalCashIn === false}>
                  {i18n.t("out")}
                </IrrCellLabel>
                <IrrCellValue
                  updated={row.isUpdated}
                  value={totalCashOut}
                  currency={this.props.currency}
                  roundDown={true}
                  maxLongFormatValue={999999}
                />
              </>
            )}
          </IrrSummaryCell>
        );
      }
    };

    const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.currency);
    valueCell.supportedTickerTypes = [
      tickerTypes.FIAT,
      tickerTypes.CRYPTO,
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ];
    const padCell = new GridCellData(cellType.PADDING, "", null);

    var cells = [nameCell, irrCell, valueCell, detailsCell];
    if (this.props.irrType !== irrTypes.HOLDING) {
      cells = [nameCell, padCell, valueCell, detailsCell];
    }

    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      const name = cells[0].value;
      const value = cells[2].value;

      if (name && name.length > 0 && (value !== null && value !== undefined)) {
        return true;
      }
      return false;
    });

    rowData.getContextMenuItems = row => [];
    if (this.props.isReadOnlyWlClient) {
      rowData.isManaged = true;
      rowData.isInvestable = true;
    }
    return rowData;
  }

  handleIrrCellClick = (e, sheetIndex, sectionIndex, rowIndex) => {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    CustodianDetailsComponentExports.show(this.props.history, this.props.location, row.id, undefined, undefined, {
      hash: `${hashParams.TAB_INDEX}=${detailsTabs.RETURNS}`
    });
  };

  handleDetailsClick = (sheetIndex, sectionIndex, rowIndex) => {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    CustodianDetailsComponentExports.show(this.props.history, this.props.location, row.id, undefined, undefined);
  };

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    const valueObj = updatedRow.cells[2].isEditable
      ? {
          value: updatedRow.cells[2].value,
          valueTickerId: updatedRow.cells[2].getTickerId(),
          valueExchangeRate: updatedRow.cells[2].exchangeRateDetails,
          valueInvalidInputText: updatedRow.cells[2].invalidInputText,
          valueTickerInfo: updatedRow.cells[2].tickerInfo
        }
      : null;

    const upadateObj = {
      name: updatedRow.cells[0].value,
      ...valueObj
    };

    const newGridData = this.state.gridData;
    const row = newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

    const holdingsIndex = this.props.holdings.findIndex(item => item.id === updatedRow.id);
    const promise = this.props.updateHolding(this.props.holdings[holdingsIndex], upadateObj);
    this.updateHoldingsPromiseList[row.id] = promise;
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleCellInvalidTickerAdded(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
    const cell = row.cells[cellIndex];

    if (!cell.invalidInputText === false && cell.loading === false) {
      cell.loading = true;
      this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);

      this.props.fetchTickerDetails(
        cell.invalidInputText,
        new Date(),
        result => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];

          cell.loading = false;
          if (!result === true) {
            this.props.showToastTip("TIP", i18n.t("invalidTickerError"), null, 10000);
            this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
            return;
          }

          cell.exchangeRateDetails = result.exchangeRateDetails;
          cell.currency = result.tickerShortName;
          cell.invalidInputText = null;

          const newRow = row.clone();
          this.updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex);
          this.handleRowUpdate(sheetIndex, sectionIndex, rowIndex, newRow, false);
        },
        error => {
          const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];
          const cell = row.cells[cellIndex];
          cell.loading = false;
          this.updateGridRow(row.clone(), sheetIndex, sectionIndex, rowIndex);
          this.props.showToastTip("TIP", i18n.t("tickerFetchFailure"), null, 10000);
        }
      );
    }
  }

  updateGridRow(newRow, sheetIndex, sectionIndex, rowIndex) {
    const newGridData = this.state.gridData;
    newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex] = newRow;
    this.setState({ gridData: newGridData });
  }

  getEmptyHoldingsMessage() {
    const holdingsTimer = !this.isCryptoHoldings() ? 180 : 3600;
    if (this.props.holdings.length !== 0 && this.state.gridData.sheets[0].sections[0].rows.length === 0) {
      return i18n.t("custodianDetails.noHoldingsMessage");
    } else if (new Date().getTime() / 1000 - this.props.lastUpdateTs < holdingsTimer) {
      return !this.isCryptoHoldings()
        ? i18n.t("custodianDetails.checkingHoldingsMessage")
        : i18n.t("custodianDetails.checkingHoldingsMessageCrypto");
    } else if (!this.isCryptoHoldings() && new Date().getTime() / 1000 - this.props.lastUpdateTs < 2 * 60 * 60) {
      return i18n.t("custodianDetails.stillCheckingForHoldings");
    } else if (this.isCryptoHoldings()) {
      return i18n.t("custodianDetails.noHoldingsCrypto");
    }
    return i18n.t("custodianDetails.noHoldingsMessage");
  }

  isCryptoHoldings() {
    return isCryptoLinkingService(this.props.linkType);
  }

  handleExpandHoldings() {
    this.setState({ showConfirmationDialog: true });
  }

  handleConfirmationDialogPositiveButtonClick(e) {
    this.setState({ isExpanding: true });

    this.props.expandHoldingsForCustodian(
      this.props.custodianId,
      newSectionId => {
        this.props.onHoldingsExpanded();
        this.state.gridData.sheets[0].sections[0].rows.forEach(eachRow => {
          document.flashElement(eachRow.id);
        });
        document.scrollToElement(newSectionId);
      },
      apiError => {
        this.setState({ isExpanding: false });
      },
      true
    );
  }

  handleConfirmationDialogNegativeButtonClick(e) {
    this.setState({ showConfirmationDialog: false });
  }

  handleDownloadButtonClick(e, custodianId) {
    this.setState({ ...this.state, showDownloadLoader: true });
    ApiClient.getCustodianSubDetailsDownloadUrl(custodianId, "holdings", "CSV")
      .then(url => {
        downloadFile(url);
        this.setState({ ...this.state, showDownloadLoader: false });
      })
      .catch(apiError => {
        this.setState({ ...this.state, showDownloadLoader: false });
      });
  }

  render() {
    if (this.props.holdings.length === 0 || this.state.gridData.sheets[0].sections[0].rows.length === 0) {
      return (
        <Container className={this.props.className}>
          <EmptyHoldingsMessage
            dangerouslySetInnerHTML={{
              __html: this.getEmptyHoldingsMessage()
            }}
          />
        </Container>
      );
    }

    const gridData = this.state.gridData;
    const lastUpdateDetails = getCustodianLastUpdateDetails(this.props.custodianId);
    const isExpanding = this.state.isExpanding;
    const showHoldingsBadge = this.props.showHoldingsBadge;
    const custodianId = this.props.custodianId;

    return (
      <Container className={this.props.className}>
        <DetailsContainer>
          <LastUpdated isStale={lastUpdateDetails.isStale}>
            {lastUpdateDetails.dateString.replace("Last update", "Last Account Sync")}
          </LastUpdated>
          {this.props.holdings.length <= 100 && (
            <>
              {showHoldingsBadge === true && <HoldingsBadge />}
              {!this.props.isReadOnly === true && (
                <ExpandHoldingsButton onClick={this.handleExpandHoldings}>
                  {isExpanding === true ? i18n.t("expandingHoldings") : i18n.t("expandHoldings")}
                </ExpandHoldingsButton>
              )}
            </>
          )}
          &nbsp;
          <DownloadButtonContainer>
            {!this.state.showDownloadLoader && (
              <DownloadButton onClick={e => this.handleDownloadButtonClick(e, custodianId)}>
                <CSVDownloadIcon></CSVDownloadIcon>
              </DownloadButton>
            )}
            {this.state.showDownloadLoader && (
              <DownloadLoaderContainer>
                <Loader />
              </DownloadLoaderContainer>
            )}
          </DownloadButtonContainer>
        </DetailsContainer>
        <ThemeProvider theme={theme}>
          <Grid
            title={i18n.t("assetsComponent.title")}
            gridData={gridData}
            getEmptyRow={this.getEmptyRow}
            onChange={this.handleChange.bind(this)}
            onRowUpdate={this.handleRowUpdate}
            onCellInvalidTickerAdded={this.handleCellInvalidTickerAdded}
            onAddNewRow={this.handleAddNewRow}
            onDetailsClick={this.handleDetailsClick}
          />
        </ThemeProvider>

        {this.state.showConfirmationDialog && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("expandHoldingsDialog.title")}
            description={i18n
              .t("expandHoldingsDialog.description")
              .split("%s1%")
              .join(this.props.category === category.ASSET ? "assets" : "debts")}
            isLoading={this.state.isExpanding}
            positiveButtonTitle={i18n.t("yes")}
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleConfirmationDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleConfirmationDialogNegativeButtonClick}
          />
        )}
        {this.props.showPoweredByCartaLogo && <PoweredByCartaLogoComponent />}
      </Container>
    );
  }
}

CustodianHoldingsComponent.defaultProps = {
  valueMultiplier: 1
};

const mapStateToProps = (state, props) => ({
  getOwnershipValueForCustodian: getOwnershipValueForCustodian.bind(state),
  getTickerUsingId: getTickerUsingId.bind(state)
});

const mapDispatchToProps = {
  expandHoldingsForCustodian: expandHoldingsForCustodian,
  updateHolding: updateCustodianHolding,
  showToastTip: showToastTip,
  fetchTickerDetails: fetchTickerDetails,
  refreshCustodian: refreshCustodian,
  fetchDetails: fetchCustodianDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustodianHoldingsComponent));
