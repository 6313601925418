import i18n from "locale/i18n";
import localforage from "localforage";
import { store } from "../../store";
import {
  getPortfolioSessionUserId,
  getLastUsedPortfolioUserId,
  recapReportComparisonDataSelector,
  currentPortfolioSelector,
  portfoliosSelector,
  custodianSelector,
  sheetAndSectionReportNodeSelector,
  recapReportContentsDataSelector,
  reportTargetPercentageSelector,
  chartTimeRange,
  recapChartOptions,
  chartContent,
  getTickerUsingShortName,
  getTickerUsingId,
  getExchangeRate,
  formatNumberWithKuberaNumberFormatSettings,
  siteConfigSelector,
  SIMILAR_FORMAT_CURRENCIES
} from "../reducers/Common";
import { decompressString, compressObject } from "../../utilities/CompressedSerialize";
import { isAppInViewMode } from "../../utilities/Common";
import DeferredPromise from "../../utilities/DeferredPromise";
import { isMobile } from "../../utilities/Location";
import { getPercentageValue } from "../../utilities/Number";
import { captureError } from "../../utilities/CaptureError";
import merge from "lodash.merge";
import { createSelector } from "reselect";

const isMobileDevice = isMobile();

export const FORCE_ERROR_TYPES = {
  ERROR_BOUNDARY: 1,
  MEMORY_ERROR: 2
};
export const WL_PLACEHOLDER_EMAIL = "client-8efb";
export const SAVE_RECAP_TO_DB = "SAVE_RECAP_TO_DB";
export const UPDATE_RECAP = "UPDATE_RECAP";
export const MIN_CHART_DATA_POINTS = 5;
export const REHYDRATE_RECAP = "REHYDRATE_RECAP";
export const LAST_FETCH_MAX_AGE = 172800000;
export const tickersRehydratedPromise = new DeferredPromise();
export const categoryType = {
  ASSET: "Asset",
  DEBT: "Debt",
  INSURANCE: "Insurance",
  ALL: "All"
};
export const GET_FUND_SCHEDULE_DATA_SUCCESS = "GET_FUND_SCHEDULE_DATA_SUCCESS";
export const FETCH_PORTFOLIOS_PENDING = "FETCH_PORTFOLIOS_PENDING";
export const FETCH_PORTFOLIOS_ERROR = "FETCH_PORTFOLIOS_ERROR";
export const SET_PORTFOLIOS = "SET_PORTFOLIOS";
export const INSERT_PORTFOLIO = "INSERT_PORTFOLIO";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const UPDATE_CURRENT_PORTFOLIO = "UPDATE_CURRENT_PORTFOLIO";
export const INSERT_CUSTODIAN = "INSERT_CUSTODIAN";
export const UPDATE_CUSTODIAN_BULK = "UPDATE_CUSTODIAN_BULK";
export const DELETE_CUSTODIAN = "DELETE_CUSTODIAN";
export const DELETE_CUSTODIAN_BULK = "DELETE_CUSTODIAN_BULK";
export const INSERT_SECTION = "INSERT_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const MOVE_SECTION = "MOVE_SECTION";
export const UPDATE_SHEET = "UPDATE_SHEET";
export const MOVE_SHEET = "MOVE_SHEET";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const BULK_CHANGE_CUSTODIAN_STAR_STATUS = "BULK_CHANGE_CUSTODIAN_STAR_STATUS";
export const BULK_CHANGE_CUSTODIAN_UPDATED_STATUS = "BULK_CHANGE_CUSTODIAN_UPDATED_STATUS";
export const DELETE_SECTION = "DELETE_SECTION";
export const DELETE_SHEET = "DELETE_SHEET";
export const INSERT_SHEET = "INSERT_SHEET";
export const INSERT_DOCUMENT = "INSERT_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SET_PORTFOLIO_LAST_FORCE_REFRESH_TS = "SET_PORTFOLIO_LAST_FORCE_REFRESH_TS";
export const SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS = "SET_PORTFOLIO_CHANGE_DATA_LAST_FORCE_REFRESH_TS";
export const FETCH_NET_WORTH_DATA_PENDING = "FETCH_NET_WORTH_DATA_PENDING";
export const FETCH_NET_WORTH_DATA_SUCCESS = "FETCH_NET_WORTH_DATA_SUCCESS";
export const SET_FF_PAYLOAD = "SET_FF_PAYLOAD";
export const FETCH_NET_WORTH_DATA_ERROR = "FETCH_NET_WORTH_DATA_ERROR";
export const FETCH_RECAP_DATA_ERROR = "FETCH_RECAP_DATA_ERROR";
export const FETCH_RECAP_DATA_PENDING = "FETCH_RECAP_DATA_PENDING";
export const FETCH_RECAP_DATA_SUCCESS = "FETCH_RECAP_DATA_SUCCESS";
export const FETCH_INITIAL_RECAP_DATA_PENDING = "FETCH_INITIAL_RECAP_DATA_PENDING";
export const FETCH_INITIAL_RECAP_DATA_ERROR = "FETCH_INITIAL_RECAP_DATA_ERROR";
export const SET_LINK_TYPE = "SET_LINK_TYPE";
export const RESET_PORTFOLIO_STATE = "RESET_PORTFOLIO_STATE";
export const SET_SLIDE_DIRECTION = "SET_SLIDE_DIRECTION";
export const SET_SHOW_REFRESHING = "SET_SHOW_REFRESHING";
export const PAGE_RELOADING = "PAGE_RELOADING";
export const REFRESH_CUSTODIAN_DONE = "REFRESH_CUSTODIAN_DONE";
export const SET_SECTION_UPDATED = "SET_SECTION_UPDATED";
export const REMOVE_SECTION_UPDATED = "REMOVE_SECTION_UPDATED";
export const FETCH_PORTFOLIO_CHANGE_DATA_PENDING = "FETCH_PORTFOLIO_CHANGE_DATA_PENDING";
export const FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS = "FETCH_PORTFOLIO_CHANGE_DATA_SUCCESS";
export const FETCH_PORTFOLIO_CHANGE_DATA_ERROR = "FETCH_PORTFOLIO_CHANGE_DATA_ERROR";
export const UPDATE_SHOW_CHARTS_MODAL_LOADER = "UPDATE_SHOW_CHARTS_MODAL_LOADER";
export const FETCH_PERCENTAGE_ALLOCATION_DATA_PENDING = "FETCH_PERCENTAGE_ALLOCATION_DATA_PENDING";
export const FETCH_PERCENTAGE_ALLOCATION_DATA_SUCCESS = "FETCH_PERCENTAGE_ALLOCATION_DATA_SUCCESS";
export const recapChartTypes = {
  TOTALS: "totals",
  PERCENTAGE_ALLOCATION: "percentageAllocation"
};
export const THEME_SUFFIX_STR = isMobileDevice ? "" : "-desktop";
export const INITIAL_FETCH_PORTFOLIOS_SUCCESS = "INITIAL_FETCH_PORTFOLIOS_SUCCESS";
export const REMOVE_CONNECTION_ERRORS = "REMOVE_CONNECTION_ERRORS";
export const accountLinkingService = {
  YODLEE: 1,
  PLAID: 2,
  ZABO: 3,
  SALTEDGE: 4,
  SALTEDGE_EU: 5,
  ZILLOW: 6,
  DOMAINS: 7,
  CARS: 8,
  ZERION: 9,
  IN_HOUSE_CRYPTO_OAUTH: 10,
  IN_HOUSE_CRYPTO_API: 11,
  FLINKS_BANKING: 12,
  FLINKS_INVESTMENT: 13,
  AKAHU: 14,
  LEAN: 15,
  FINICITY: 16,
  SNAPTRADE: 17,
  MX: 18,
  IN_HOUSE_OAUTH: 20,
  KUBERA_PORTFOLIO: 19
};
export const FETCH_PORTFOLIOS_SUCCESS = "FETCH_PORTFOLIOS_SUCCESS";
export const chartTimeRangeGroups = {
  GROUP_BY_DAY: "groupByDay",
  GROUP_BY_WEEK: "groupByWeek",
  GROUP_BY_MONTH: "groupByMonth",
  GROUP_BY_YEAR: "groupByYear"
};
export const chartStyle = {
  DOUGHNUT: "doughnut",
  LINE: "line",
  OTHER: "other"
};
export const RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS = "Investable Assets";
export const RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS_WITHOUT_CASH = "Investable Assets ex Cash";
export const chartKeyParams = {
  IS_DEFAULT_CHART: "is_default_chart",
  IS_CHECKED: "is_checked"
};
export const reportPaths = {
  ASSETS: "Assets",
  DEBTS: "Debts",
  ASSETS_SHEET: "Assets/sheets",
  ASSETS_SECTION: "Assets/sections",
  ASSETS_ROW: "Assets/rows",
  DEBTS_SHEETS: "Debts/sheets",
  DEBTS_SECTION: "Debts/sections",
  DEBTS_ROW: "Debts/rows"
};
export const irrTypes = {
  COSTBASIS: "costbasis",
  CASHFLOW: "cashflow",
  HOLDING: "holding"
};
export const GET_CONNECTIVITY_CENTER_DATA_ERROR = "GET_CONNECTIVITY_CENTER_DATA_ERROR";
export const GET_CONNECTIVITY_CENTER_DATA_PENDING = "GET_CONNECTIVITY_CENTER_DATA_PENDING";
export const GET_CONNECTIVITY_CENTER_DATA_SUCCESS = "GET_CONNECTIVITY_CENTER_DATA_SUCCESS";
export const UPDATE_CONNECTIVITY_CENTER_DATA = "UPDATE_CONNECTIVITY_CENTER_DATA";
export const custodianSubTypes = {
  CAR: "car",
  CRYPTO: "crypto",
  DOMAIN: "domain",
  HOME: "home",
  CREDIT_CARD: "credit card",
  INVESTMENT: "investment",
  LOAN: "loan",
  CASH: "cash",
  STOCK: "stock",
  FIXED_INCOME: "fixed income",
  BOND: "bond",
  MUTUAL_FUND: "mutual fund",
  DERIVATIVE: "derivative",
  ETF: "etf",
  INSURANCE: "insurance",
  OTHER_FIXED: "other2"
};
export const holdingSubTypes = Object.freeze({
  STOCK_OPTION: "stock_option",
  CONVERTIBLE_NOTE: "convertible_note",
  RSA: "rsa",
  RSU: "rsu",
  CERTIFICATE: "certificate"
});
export const custodianTaxTypes = {
  TAXABLE: 0,
  TAX_DEFERRED: 1,
  TAX_FREE: 2
};
export const ADD_SCENARIO = "ADD_SCENARIO";
export const DELETE_SCENARIO = "DELETE_SCENARIO";
export const ADD_RULE = "ADD_RULE";
export const DELETE_RULE = "DELETE_RULE";
export const UPDATE_RULE = "UPDATE_RULE";
export const UPDATE_RULE_BULK = "UPDATE_RULE_BULK";
export const UPDATE_SCENARIO = "UPDATE_SCENARIO";
export const ADD_REPORT_PREFERENCE = "ADD_REPORT_PREFERENCE";
export const ADD_DIY_CHART = "ADD_DIY_CHART";
export const DELETE_DIY_CHART = "DELETE_DIY_CHART";
export const SET_BACKGROUND_LINK_FAILURE_DATA = "SET_BACKGROUND_LINK_FAILURE_DATA";
export const SET_POPULAR_PROVIDERS = "SET_POPULAR_PROVIDERS";
export const tickerTypes = {
  FIAT: "fiat",
  CRYPTO: "crypto",
  STOCK: "stock",
  FUND: "fund",
  BOND: "bond",
  DERIVATIVE: "derivative",
  INDEX: "index"
};
export const FETCH_ARCHIVED_PORTFOLIO = "FETCH_ARCHIVED_PORTFOLIO";
export const FETCH_ARCHIVED_PORTFOLIO_SUCCESS = "FETCH_ARCHIVED_PORTFOLIO_SUCCESS";
export const FETCH_ARCHIVED_PORTFOLIO_ERROR = "FETCH_ARCHIVED_PORTFOLIO_ERROR";
export const RESET_ARCHIVED_PORTFOLIO = "RESET_ARCHIVED_PORTFOLIO";
export const DELETE_ARCHIVED_CUSTODIAN = "DELETE_ARCHIVED_CUSTODIAN";
export const SET_STATUS_CONFIG = "SET_STATUS_CONFIG";
export const SET_SITE_CONFIG = "SET_SITE_CONFIG";
export const REMOVE_STRIPE_CONNECTED_ACCOUNT = "REMOVE_STRIPE_CONNECTED_ACCOUNT";
export const SET_APP_MAINTENANCE_STATUS = "SET_APP_MAINTENANCE_STATUS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SET_USER = "SET_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_SIGNIN_REDIRECT_PATH = "SET_SIGNIN_REDIRECT_PATH";
export const SET_SIGN_IN_WITH_GOOGLE_FLOW = "SET_SIGN_IN_WITH_GOOGLE_FLOW";
export const SET_SIGN_UP_WITH_GOOGLE_FLOW = "SET_SIGN_UP_WITH_GOOGLE_FLOW";
export const FETCH_CREDIT_BALANCE_PENDING = "FETCH_CREDIT_BALANCE_PENDING";
export const UPDATE_CREDIT_BALANCE = "UPDATE_CREDIT_BALANCE";
export const FETCH_MULTIUSER_LIST_PENDING = "FETCH_MULTIUSER_LIST_PENDING";
export const SET_MULTIUSER_LIST = "SET_MULTIUSER_LIST";
export const SET_MULTIUSER_INVITATION_DATA = "SET_MULTIUSER_INVITATION_DATA";
export const SET_SHARE_MASK = "SET_SHARE_MASK";
export const SET_RECEIPTS = "SET_RECEIPTS";
export const SHOW_BREAKING_CHANGES = "SHOW_BREAKING_CHANGES";
export const ADD_USER_ACTION = "ADD_USER_ACTION";
export const LINK_PORTFOLIO_ACTION = "LINK_PORTFOLIO_ACTION";
export const PVST_VALUE_TICKER_ID = 171;
export const BLACK_FEATURE_TRIGGERED = "BLACK_FEATURE_TRIGGERED";
export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: "active",
  CANCELED: "canceled",
  PAST_DUE: "past_due",
  EXPIRED: "expired",
  TRIALING: "trialing",
  INCOMPLETE_EXPIRED: "incomplete_expired"
});
export const SUBSCRIPTION_PLANS = Object.freeze({
  TRIAL: "trial",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  YEARLY_FAMILY: "yearly_family",
  YEARLY_BLACK: "yearly_black"
});
export const INITIAL_CARD_SETUP_TIMESTAMP = 1623821400;
export const API_ACCESS_TIMESTAMP = 1724220031;
export const wlSetupType = {
  ADMIN_WR_CLIENT_WR: "admin_write, client_write",
  ADMIN_WR_CLIENT_RO: "admin_write, client_read"
};
export const FETCH_WL_DASHBOARD_PENDING = "FETCH_WL_DASHBOARD_PENDING";
export const FETCH_WL_DASHBOARD_SUCCESS = "FETCH_WL_DASHBOARD_SUCCESS";
export const FETCH_WL_DASHBOARD_ERROR = "FETCH_WL_DASHBOARD_ERROR";
export const WL_ADD_CLIENT = "WL_ADD_CLIENT";
export const WL_UPDATE_CLIENT = "WL_UPDATE_CLIENT";
export const WL_UPDATE_DASHBOARD_CLIENT = "WL_UPDATE_DASHBOARD_CLIENT";
export const WL_DELETE_CLIENT = "WL_DELETE_CLIENT";
export const WL_ADD_MANAGER = "WL_ADD_MANAGER";
export const WL_UPDATE_MANAGER = "WL_UPDATE_MANAGER";
export const WL_DELETE_MANAGER = "WL_DELETE_MANAGER";
export const WL_ADD_SUBUSER = "WL_ADD_SUBUSER";
export const WL_DELETE_SUBUSER = "WL_DELETE_SUBUSER";
export const WL_SET_CLIENT_CONTEXT = "WL_SET_CLIENT_CONTEXT";
export const WL_SET_ADMIN_LIST_CHARGES = "WL_SET_ADMIN_LIST_CHARGES";
export const WL_SET_FILTER_CLIENTS_BY_WL_MANAGER = "WL_SET_FILTER_CLIENTS_BY_WL_MANAGER";
export const wlUserStatus = {
  ADDED: "added",
  INVITED: "invited",
  ACCEPTED: "accepted"
};

// @INFO: This is a placeholder for the WLK payment method types
export const wlPaymentMethodType = {
  CARD: "CARD",
  BANK: "BANK",
  BACS: "BACS"
};
export const FETCH_BENEFICIARY_ERROR = "FETCH_BENEFICIARY_ERROR";
export const UPDATE_BENEFICIARY = "UPDATE_BENEFICIARY";
export const FETCH_CUSTODIAN_DETAILS = "FETCH_CUSTODIAN_DETAILS";
export const FETCH_CUSTODIAN_DETAILS_SUCCESS = "FETCH_CUSTODIAN_DETAILS_SUCCESS";
export const FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS = "FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS";
export const FETCH_CUSTODIAN_DETAILS_ERROR = "FETCH_CUSTODIAN_DETAILS_ERROR";
export const UPDATE_CUSTODIAN_DETAILS = "UPDATE_CUSTODIAN_DETAILS";
export const RESET_CUSTODIAN_DETAILS = "RESET_CUSTODIAN_DETAILS";
export const DELETE_CUSTODIAN_HISTORY = "DELETE_CUSTODIAN_HISTORY";
export const UPDATE_CUSTODIAN_HISTORY = "UPDATE_CUSTODIAN_HISTORY";
export const REFRESH_CUSTODIAN_HISTORY = "REFRESH_CUSTODIAN_HISTORY";
export const UPDATE_CUSTODIAN_CASHFLOW = "UPDATE_CUSTODIAN_CASHFLOW";
export const DELETE_CUSTODIAN_CASHFLOW = "DELETE_CUSTODIAN_CASHFLOW";
export const ADD_PENDING_IRR_REQUEST = "ADD_PENDING_IRR_REQUEST";
export const REMOVE_PENDING_IRR_REQUEST = "REMOVE_PENDING_IRR_REQUEST";
export const SET_INNER_DETAILS_STATE = "SET_INNER_DETAILS_STATE";
export const SET_CURRENT_STATE = "SET_CURRENT_STATE";
export const UPDATE_FUND_SCHEDULE = "UPDATE_FUND_SCHEDULE";
export const DELETE_FUND_SCHEDULE = "DELETE_FUND_SCHEDULE";
export const GET_CUSTODIAN_CHART = "GET_CUSTODIAN_CHART";
export const GET_CUSTODIAN_CHART_SUCCESS = "GET_CUSTODIAN_CHART_SUCCESS";
export const GET_CUSTODIAN_CHART_ERROR = "GET_CUSTODIAN_CHART_ERROR";
export const SET_PLUGIN_CURRENCY = "SET_PLUGIN_CURRENCY";
export const SET_LONG_EQUITY_DATA = "SET_LONG_EQUITY_DATA";
export const SAVE_PLUGIN_DATA = "SAVE_PLUGIN_DATA";
export const UPDATE_CASH_FORECAST_DURATION = "UPDATE_CASH_FORECAST_DURATION";
export const UPDATE_TICKER_DATA = "UPDATE_TICKER_DATA";
export const ADD_TICKER_INFO = "ADD_TICKER_INFO";
export const SHOW_TOAST = "SHOW_TOAST";
export const DISMISS_TOAST = "DISMISS_TOAST";
export const AI_DOCUMENT_IMPORT_PAYWALL_ACTION = "AI_DOCUMENT_IMPORT_PAYWALL_ACTION";
export const toastType = {
  UNDO: "UNDO",
  SYNC_ERROR: "SYNC_ERROR",
  SYNC_RESUMING: "SYNC_RESUMING",
  GENERIC_ERROR: "GENERIC_ERROR",
  TIP: "TIP"
};
// possible configurations for each rule
export const planningVariables = {
  ASSET_TYPE: "asset_type",
  DEBT_TYPE: "debt_type",
  RATE_PER_YEAR: "rate_per_year",
  RATE_PER_YEAR_WITH_TAX: "rate_per_year_with_tax",
  GROWTH_RATE: "growth_rate",
  PERCENTAGE: "percentage",
  REVISED_PERCENTAGE: "revised_percentage",
  ASSET_ID: "asset_id",
  DEBT_ID: "debt_id",
  AMOUNT: "amount",
  AMOUNT_WITH_TAX: "amount_with_tax",
  COST_WITH_TAX: "cost_with_tax",
  EXPECTED_AMOUNT: "expected_amount",
  DATE_AGE: "date_age",
  DATE_AGE_YEAR: "date_age_year",
  DATE_AGE_REVISED: "date_age_revised",
  NO_DUPLICATE: "no_duplicate",
  MULTIPLE_DATES: "multi_date",
  TICKER_ID: "ticker_id",
  TICKER_ID_OBJ: "ticker_id_obj",
  QUANTITY: "quantity",
  MONTHS: "months",
  YEARS: "years",
  REPEAT: "repeat",
  NEW_ASSET: "new_asset",
  NEW_DEBT: "new_debt",
  NEW_INCOME: "new_income",
  NEW_EXPENSE: "new_expense",
  META: "meta",
  TICKER_NAME: "ticker_name",
  TAX: "tax",
  TAX_DEDUCTION_AMOUNT: "tax_deduction",
  TAX_DEDUCTION_RATE: "tax_deduction_rate",
  VESTING_SCHEDULE: "vesting_schedule",
  AUTO_AMOUNT_WITH_TAX: "auto_amount_with_tax",
  LIQUIDATION_RATE_WITH_TAX: "liquidation_rate_with_tax",
  CAPITAL_SCHEDULE: "capital_schedule"
};
export const planningAssetTypes = {
  all: {
    label: "All",
    key: "all"
  },
  investable: {
    label: "Investable Assets",
    key: "investable"
  },
  cash: {
    label: "Cash",
    key: "cash"
  },
  stocks: {
    label: "Stocks",
    key: "stocks"
  },
  bonds: {
    label: "Bonds",
    key: "bonds"
  },
  funds: {
    label: "Funds",
    key: "funds"
  },
  metals: {
    label: "Precious Metals",
    key: "metals"
  },
  investments: {
    label: "Investments",
    key: "investments"
  },
  crypto: {
    label: "Crypto",
    key: "crypto"
  },
  homes: {
    label: "Real Estate",
    key: "homes"
  },
  derivatives: {
    label: "Derivatives",
    key: "derivatives"
  },
  cash_equivalents: {
    label: "Cash Equivalents",
    key: "cash_equivalents"
  },
  other: {
    label: "Miscellaneous",
    key: "other"
  },
  taxable: {
    label: "Taxable",
    key: "taxable"
  },
  taxDeferred: {
    label: "Tax Deferred",
    key: "taxDeferred"
  },
  taxFree: {
    label: "Tax Free",
    key: "taxFree"
  }
};
// set the frequency for repeating rules
export const repeatFrequency = {
  NO_REPEAT: "no_repeat",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  BI_ANNUALLY: "bi_annually",
  YEARLY: "yearly"
};
export const timeRanges = [
  chartTimeRange.WEEKLY,
  chartTimeRange.MONTHLY,
  chartTimeRange.QUARTERLY,
  chartTimeRange.YEARLY,
  chartTimeRange.ALL
];
export const OVERRIDE_INITIAL_CC_SETUP_LIST = [
  "kpnv8523@gmail.com",
  "kpnavaneetha@gmail.com",
  "sk11985235@gmail.com",
  "kpnva98@gmail.com",
  "testkubera1@gmail.com",
  "testkubera2@gmail.com",
  "testkubera12345@gmail.com",
  "unclescrooge31@gmail.com",
  "jonsnowappleseed@gmail.com",
  "cm.layouttest@gmail.com",
  "manojmarathayil@gmail.com"
];

// Methods
export const updateDashboardAction = updatedEntitiesArray => ({
  type: UPDATE_DASHBOARD,
  updatedEntitiesArray
});

export const setPageReloadingAction = bool => {
  return dispatch => {
    dispatch({
      type: PAGE_RELOADING,
      isLoading: bool
    });
  };
};

export const setPortfoliosAction = (portfolios, showNonEmptyAsCurrent = false) => ({
  type: SET_PORTFOLIOS,
  portfolios,
  showNonEmptyAsCurrent
});

export const resetPortfolioStateAction = () => ({
  type: RESET_PORTFOLIO_STATE
});

export const defaultTicker = () => {
  const portfolio = currentPortfolioSelector(store.getState());
  return getTickerUsingShortName(portfolio.currency);
};

export const getAccountLinkingService = linkType => {
  switch (linkType) {
    case 1:
      return "yodlee";
    case 2:
      return "plaid";
    case 3:
      return "zabo";
    case 4:
      return "saltedge";
    case 5:
      return "saltedge_eu";
    case 6:
      return "zillow";
    case 7:
      return "estibot";
    case 8:
      return "vinaudit";
    case 9:
      return "zerion";
    case 10:
      return "crypto_oauth";
    case 11:
      return "crypto_api";
    case 12:
      return "flinks_banking";
    case 13:
      return "flinks_investment";
    case 14:
      return "akahu";
    case 15:
      return "lean";
    case 16:
      return "finicity";
    case 17:
      return "snaptrade";
    case 18:
      return "mx";
    case 19:
      return "kubera_portfolio";
    case 20:
      return "in_house_oauth";
    default:
      return linkType;
  }
};

export const isZaboToInHouseApiCandidate = linkProviderId => {
  if (!linkProviderId === true) {
    return false;
  }
  return [
    "binance",
    "binanceus",
    "bitcoin",
    "bitcoincash",
    "bitfinex",
    "bitstamp",
    "bittrex",
    "bybit",
    "cardano",
    "celsius",
    "coinbaseprime",
    "coinbasepro",
    "cryptocom",
    "dogecoin",
    "ethereumclassic",
    "ftx",
    "ftxus",
    "gateio",
    "hitbtc",
    "huobi",
    "kraken",
    "kucoin",
    "liquid",
    "litecoin",
    "okcoin",
    "poloniex",
    "ripple",
    "stellar",
    "tezos",
    "trezor",
    "ledger",
    "xpub-key",
    "xpubbitcoin"
  ].includes(linkProviderId.toLowerCase());
};

export const isZaboToInHouseOauthCandidate = linkProviderId => {
  if (!linkProviderId === true) {
    return false;
  }
  return ["coinbase", "gemini"].includes(linkProviderId.toLowerCase());
};

export const getExchangeRateDetails = (tickerId, rate, forDate = null) => {
  const date = !forDate === true ? new Date() : forDate;
  const dateString = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(
    -2
  )}`;
  return JSON.stringify({ tickerId: tickerId, rate: rate, date: dateString });
};

export const custodiansLinkedToSameAccount = (
  custodianId,
  excludeChildren = true,
  excludeHidden = false,
  isRemoveFlow
) => {
  const portfolios = portfoliosSelector(store.getState());
  const custodian = custodianSelector(store.getState(), custodianId);
  let custodians = [];
  let portfolioCustodiansMap = {};
  let custodianPortfolioMap = {};

  if (!portfolios === true || !custodian === true) {
    return { custodians, custodianPortfolioMap, portfolioCustodiansMap };
  }

  for (const portfolio of portfolios) {
    let matchingCustodians = [];
    if (isRemoveFlow) {
      matchingCustodians = portfolio.details.custodian.filter(
        temp =>
          temp.linkContainer !== "nft" &&
          !temp.linkProviderAccountId === false &&
          temp.linkProviderAccountId === custodian.linkProviderAccountId &&
          !temp.linkProviderId === false &&
          temp.linkProviderId === custodian.linkProviderId
      );
    } else {
      matchingCustodians = portfolio.details.custodian.filter(
        temp =>
          !temp.linkProviderAccountId === false &&
          temp.linkProviderAccountId === custodian.linkProviderAccountId &&
          !temp.linkProviderId === false &&
          temp.linkProviderId === custodian.linkProviderId
      );
    }

    if (excludeChildren === true) {
      matchingCustodians = matchingCustodians.filter(item => !item.parentId === true);
    }
    if (excludeHidden === true) {
      matchingCustodians = matchingCustodians.filter(item => item.hidden === 0);
    }
    if (matchingCustodians.length > 0) {
      for (const item of matchingCustodians) {
        custodianPortfolioMap[item.id] = portfolio.id;
      }
      portfolioCustodiansMap[portfolio.id] = matchingCustodians;
      custodians.push(...matchingCustodians);
    }
  }
  return { custodians, portfolioCustodiansMap, custodianPortfolioMap };
};

export const getRecapWorkerConsts = () => {
  return {
    UPDATE_RECAP,
    SAVE_RECAP_TO_DB,
    RECAP_DATA_STORAGE_KEY: getRecapDataStorageKey()
  };
};

export const getLineChartTitle = (selectedChartOptions, reportPath, reportName, reportId) => {
  if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
    if (reportPath === "Assets/sheets" || reportPath === "Debts/sheets") {
      return `${reportName} (Sheet)`;
    } else if (reportPath === "Assets/sections" || reportPath === "Debts/sections") {
      const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, chartTimeRange.TODAY, true);
      return `${parentNode.name} / ${reportName}`;
    }
  }
  return reportName;
};

export const getRecommendationCountForAChart = (reportId, chartContentType, percentages) => {
  try {
    let reportData;
    let recommendationCount = 0;
    // shouldCompareAgainstInvestableAssets
    if (chartContentType === "contents") {
      reportData = recapReportContentsDataSelector(store.getState(), reportId);
    } else if (chartContentType === "reports") {
      reportData = recapReportComparisonDataSelector(store.getState(), reportId);
    } else if (chartContentType === chartContent.CONTENTS_GROUPED_BY_SHEETS_AND_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_GROUPED_BY_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SHEETS_AND_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, true);
    } else if (chartContentType === chartContent.INVESTABLE_ASSETS_WITHOUT_CASH_GROUPED_BY_SECTION) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, false, true);
    } else if (chartContentType === chartContent.ASSETS_GROUPED_BY_SECTIONS) {
      reportData = recapReportContentsDataSelector(store.getState(), reportId, false, false, false, false, true);
    }

    if (reportData && reportData.contents) {
      for (const content of reportData.contents) {
        const actualPercentage = getPercentageValue(content.value, reportData.total, false, 2);
        const storedTargetPrecentage = reportTargetPercentageSelector(
          store.getState(),
          reportId,
          chartContentType,
          content.id || content.name
        );
        if (
          storedTargetPrecentage !== null &&
          storedTargetPrecentage !== undefined &&
          storedTargetPrecentage !== "" &&
          (storedTargetPrecentage <= actualPercentage - 5 || storedTargetPrecentage >= actualPercentage + 5)
        ) {
          recommendationCount++;
        }
      }
    }
    return recommendationCount;
  } catch (e) {
    console.log("e", e);
  }
};

export const getYTDStartDate = () => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, 0, 1);
};

export const isCryptoLinkingService = linkType => {
  if (!linkType === true) {
    return false;
  }

  return [
    accountLinkingService.ZABO,
    accountLinkingService.ZERION,
    accountLinkingService.IN_HOUSE_CRYPTO_API,
    accountLinkingService.IN_HOUSE_CRYPTO_OAUTH
  ].includes(linkType);
};

export const isAssetCustodian = (linkContainer, linkType) => {
  if (!linkContainer === true || !linkType === true) {
    return false;
  }
  const assetContainers = ["investment", "bank", "depository", "other", "insurance"];
  return (
    assetContainers.includes(linkContainer) ||
    (isCryptoLinkingService(linkType) && ["loan"].includes(linkContainer) === false)
  );
};

export const RECAP_DATA_INITIAL_LOAD_CHUNK_KEY = "RECAP_DATA_INITIAL_LOAD_CHUNK_KEY";

export const getRecapDataStorageKey = portfolioUserId => {
  return `${RECAP_DATA_INITIAL_LOAD_CHUNK_KEY}-${portfolioUserId || getPortfolioSessionUserId()}`;
};

/************************ Handling recap persisted data fetch from localforage *****************************/
let recapDataFromLocalForage;
const getRecapDataFromLocalForage = key => {
  try {
    return localforage
      .getItem(getRecapDataStorageKey(key))
      .then(async data => {
        if (data) {
          for (const [portfolioId, chunkKeyForTimeRanges] of Object.entries(data)) {
            let recapDataForPortfolio = {};
            for (const [timeRange, chunkKeys] of Object.entries(chunkKeyForTimeRanges?.data)) {
              const decompressedData = await reconstructData(chunkKeys, timeRange);
              recapDataForPortfolio = merge(decompressedData, recapDataForPortfolio);
            }
            recapDataFromLocalForage = merge(recapDataFromLocalForage, {
              [portfolioId]: { data: recapDataForPortfolio, currency: chunkKeyForTimeRanges?.currency }
            });
          }
        }
      })
      .catch(error => {
        console.error("Error loading data from LocalForage:", error);
      });
  } catch (err) {}

  recapDataFromLocalForage = null;
};
const getRecapPortfolioSessionUserId = () => {
  return window.portfolioUserId || getPortfolioSessionUserId() || getLastUsedPortfolioUserId();
};
export const getRecapDataFromLocalForagePromise = getRecapDataFromLocalForage(getRecapPortfolioSessionUserId());
export const loadRecapDataFromLocalForage = () => {
  return async dispatch => {
    if (isAppInViewMode()) return;
    await getRecapDataFromLocalForagePromise;
    if (!recapDataFromLocalForage) return;
    // Dispatch an action to update Redux state with the retrieved data
    dispatch({
      type: REHYDRATE_RECAP,
      payload: recapDataFromLocalForage
    });
  };
};

export const reconstructData = async (chunkKeys, timeRange) => {
  try {
    if (!chunkKeys) {
      return null;
    }

    // Retrieve and combine chunks
    const chunks = [];
    for (const key of chunkKeys) {
      const chunk = await localforage.getItem(key);
      if (chunk instanceof Uint8Array) {
        chunks.push(chunk);
      } else {
        console.error(`Chunk with key ${key} is not a Uint8Array`);
        return;
      }
    }
    const totalLength = chunks.reduce((sum, chunk) => sum + chunk.length, 0);
    const combinedData = new Uint8Array(totalLength);
    let offset = 0;
    for (const chunk of chunks) {
      combinedData.set(chunk, offset);
      offset += chunk.length;
    }
    // Decompress the combined data
    const decompressedData = decompressString(combinedData);
    return decompressedData;
  } catch (error) {
    console.error("Error reconstructing data:", error);
  }
};

export const rehydrateRecapData = portfolioUserId => dispatch => {
  if (getRecapPortfolioSessionUserId() !== portfolioUserId || !recapDataFromLocalForage) {
    return;
  }

  dispatch({
    type: REHYDRATE_RECAP,
    payload: recapDataFromLocalForage
  });
};

export function saveRecapDataMapInMainThread(key, dataMap) {
  try {
    return localforage
      .setItem(key, compressObject(dataMap))
      .then(() => {
        console.log("saveRecapDataMap save successful");
      })
      .catch(e => {
        console.log("saveRecapDataMap save failed", e);
      });
  } catch (e) {
    console.log("saveRecapDataMap failed", e);
    return Promise.resolve();
  }
}

export const getPortfoliosDataStorageKey = portfolioUserId => {
  return `portfolios-${portfolioUserId || getPortfolioSessionUserId()}-v64`;
};

/************************ Handling portfolios persisted data fetch from localforage *****************************/

let portfoliosDataFromLocalForage;
const getPortfoliosDataFromLocalForage = key => {
  try {
    return localforage
      .getItem(getPortfoliosDataStorageKey(key))
      .then(data => {
        portfoliosDataFromLocalForage = data ? decompressString(data) : data;
      })
      .catch(error => {
        console.error("Error loading data from LocalForage:", error);
      });
  } catch (err) {}

  portfoliosDataFromLocalForage = null;
};
export const getPortfoliosStorePortfolioSessionUserId = () => {
  return window.portfolioUserId || getPortfolioSessionUserId() || getLastUsedPortfolioUserId();
};
export const getPortfoliosDataFromLocalForagePromise = getPortfoliosDataFromLocalForage(
  getPortfoliosStorePortfolioSessionUserId()
);
export const loadPortfoliosDataFromLocalForage = () => {
  return async (dispatch, getState) => {
    if (isAppInViewMode()) return;
    await getPortfoliosDataFromLocalForagePromise;
    if (!portfoliosDataFromLocalForage) return;
    // Dispatch an action to update Redux state with the retrieved data
    await tickersRehydratedPromise;
    if (getState().tickers.tickerData) {
      dispatch(setPortfoliosAction(portfoliosDataFromLocalForage, isMobileDevice));
    }
  };
};

export const rehydratePortfoliosData = portfolioUserId => async (dispatch, getState) => {
  if (getPortfoliosStorePortfolioSessionUserId() !== portfolioUserId || !portfoliosDataFromLocalForage) {
    return;
  }

  await tickersRehydratedPromise;
  if (getState().tickers.tickerData) {
    dispatch(setPortfoliosAction(portfoliosDataFromLocalForage, isMobileDevice));
  }
};

export const sortPortfolio = portfolio => {
  portfolio.details.custodian.sort((a, b) => (!a.sortKey === true ? 0 : a.sortKey.localeCompare(b.sortKey)));
  portfolio.details.section.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
  portfolio.details.sheet.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
};

export const getChartDataFromResponse = (
  responseData,
  dataPointsKey,
  shouldUseLatestKeys,
  isReportsData,
  networthChartStartDate
) => {
  const chartData = {};
  const dailyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.DAILY]
    : responseData[chartTimeRangeGroups.GROUP_BY_DAY];
  const weeklyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.WEEKLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_WEEK];
  const monthlyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.MONTHLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_MONTH];
  const yearlyData = shouldUseLatestKeys
    ? responseData[chartTimeRange.YEARLY]
    : responseData[chartTimeRangeGroups.GROUP_BY_YEAR];
  // Calcluate last week data
  if (dailyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.WEEKLY] = { ...dailyData };
    chartData[chartTimeRange.WEEKLY][dataPointsKey] = dailyData[dataPointsKey].filter(item => {
      return (
        new Date(item.date).getTime() >= new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
      );
    });
  } else {
    chartData[chartTimeRange.WEEKLY] = dailyData;
  }

  // Calculate last month data
  chartData[chartTimeRange.MONTHLY] = dailyData;
  chartData[chartTimeRange.MONTHLY][dataPointsKey] = dailyData[dataPointsKey].filter(
    item =>
      new Date(item.date).getTime() >= new Date(new Date().setMonth(new Date().getMonth() - 1)).setHours(0, 0, 0, 0)
  );

  // Calculate last quarter data
  if (weeklyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.QUARTERLY] = { ...weeklyData };
    chartData[chartTimeRange.QUARTERLY][dataPointsKey] = weeklyData[dataPointsKey].filter(
      item =>
        new Date(item.date).getTime() >= new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
    );
  } else {
    chartData[chartTimeRange.QUARTERLY] = dailyData;
  }

  // Calculate last year data
  if (weeklyData[dataPointsKey].length >= MIN_CHART_DATA_POINTS) {
    chartData[chartTimeRange.YEARLY] = { ...weeklyData };
    chartData[chartTimeRange.YEARLY][dataPointsKey] = weeklyData[dataPointsKey].filter(
      item =>
        new Date(item.date).getTime() >=
        new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)
    );
  } else {
    chartData[chartTimeRange.YEARLY] = dailyData;
  }

  // add one year ago data points to yearly data
  const oneYearAgoData = responseData[chartTimeRange.ONE_YEAR_AGO];
  const yearlyNetWorthData = chartData[chartTimeRange.YEARLY][dataPointsKey];
  if (
    yearlyNetWorthData.length &&
    oneYearAgoData &&
    new Date(yearlyNetWorthData[0].date).toDateString() !== new Date(oneYearAgoData.date).toDateString()
  ) {
    yearlyNetWorthData.unshift(oneYearAgoData);
  }

  const getOldestDataPoint = () => {
    const oldestDataPointInMonthlyData = monthlyData[dataPointsKey].length ? monthlyData[dataPointsKey][0] : null;
    const oldestDataPointInYearlyData = yearlyData[dataPointsKey].length ? yearlyData[dataPointsKey][0] : null;
    return new Date(oldestDataPointInMonthlyData.date) < new Date(oldestDataPointInYearlyData.date)
      ? oldestDataPointInMonthlyData
      : oldestDataPointInYearlyData;
  };

  // Calculate all data
  if (monthlyData[dataPointsKey].length) {
    const oldestDataPointFromMonthlyAndYearlyResponse = getOldestDataPoint();
    const oldestDataPoint =
      new Date(oldestDataPointFromMonthlyAndYearlyResponse.date) > networthChartStartDate
        ? oldestDataPointFromMonthlyAndYearlyResponse
        : { date: networthChartStartDate };

    const timeSinceFirstDataPoint = new Date(new Date().setHours(0, 0, 0, 0)) - new Date(oldestDataPoint?.date);
    if (timeSinceFirstDataPoint <= 31 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = dailyData;
    } else if (timeSinceFirstDataPoint <= 365 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = weeklyData;
    } else if (timeSinceFirstDataPoint <= 6 * 365 * 24 * 60 * 60 * 1000) {
      chartData[chartTimeRange.ALL] = monthlyData;
    } else {
      const data = yearlyData;
      data[dataPointsKey] = data[dataPointsKey].sort((a, b) => new Date(a.date) - new Date(b.date));

      chartData[chartTimeRange.ALL] = data;
    }
  } else {
    chartData[chartTimeRange.ALL] = dailyData;
  }

  if (responseData[chartTimeRange.LAST_YEAR_END]) {
    chartData[chartTimeRange.LAST_YEAR_END] = responseData[chartTimeRange.LAST_YEAR_END];
  }
  chartData[chartTimeRangeGroups.GROUP_BY_DAY] = responseData[chartTimeRangeGroups.GROUP_BY_DAY];
  chartData[chartTimeRangeGroups.GROUP_BY_WEEK] = responseData[chartTimeRangeGroups.GROUP_BY_WEEK];
  chartData[chartTimeRangeGroups.GROUP_BY_MONTH] = responseData[chartTimeRangeGroups.GROUP_BY_MONTH];
  chartData[chartTimeRangeGroups.GROUP_BY_YEAR] = responseData[chartTimeRangeGroups.GROUP_BY_YEAR];
  if (isReportsData) {
    chartData.custodiansFundData = responseData.custodiansFundData;
    chartData.tickerMcapList2 = responseData.tickerMcapList2;
    chartData.stockSectors = responseData.stockSectors;
    chartData.cryptoSectors = responseData.cryptoSectors;
    chartData.tickerRegionList = responseData.tickerRegionList;
    chartData.primary = responseData.primary;
    chartData.directLinkedAssetCustodianId = responseData.directLinkedAssetCustodianId;
    chartData.directLinkedDebtCustodianId = responseData.directLinkedDebtCustodianId;
    chartData.directLinkedAssetCustodianName = responseData.directLinkedAssetCustodianName;
    chartData.directLinkedDebtCustodianName = responseData.directLinkedDebtCustodianName;
    chartData.directLinkedAssetCustodianSectionId = responseData.directLinkedAssetCustodianSectionId;
    chartData.directLinkedDebtCustodianSectionId = responseData.directLinkedDebtCustodianSectionId;
    chartData.directLinkedAssetCustodianOwnership = responseData.directLinkedAssetCustodianOwnership;
    chartData.directLinkedDebtCustodianOwnership = responseData.directLinkedDebtCustodianOwnership;
    chartData.linkedAssetCustodianOwnershipHistory = responseData.linkedAssetCustodianOwnershipHistory;
    chartData.linkedDebtCustodianOwnershipHistory = responseData.linkedDebtCustodianOwnershipHistory;
    chartData.linkedPortfolioId = responseData.linkedPortfolioId;
    chartData.parentPortfolioId = responseData.parentPortfolioId;
    chartData.portfolioId = responseData.portfolioId;
    chartData.linkedAssetCustodianIdInCurrentPortfolio = responseData.linkedAssetCustodianIdInCurrentPortfolio;
    chartData.linkedDebtCustodianIdInCurrentPortfolio = responseData.linkedDebtCustodianIdInCurrentPortfolio;
  }
  return chartData;
};

export const calcCustodianOwnershipValue = (value, ownership) => {
  if (value === null || value === undefined || ownership === undefined) {
    return value;
  }

  const calculatedVal = value * (ownership / 100);
  return !isNaN(calculatedVal) ? calculatedVal : value;
};

export const getYTDCustodianChartData = (chartData, lastYearEndData, portfolioStartDate) => {
  let ytdCustodianChartData;
  let baseChartData;
  const ytdStartDate = getYTDStartDate();
  if (portfolioStartDate.getTime() > ytdStartDate.getTime()) {
    const ytdIndex = getYTDIndexInTimeRangeArray();
    return chartData.chart[timeRanges[ytdIndex - 1]];
  } else {
    const dateDifferenceFromYTD = getCurrentDateDifferenceFromYTD();
    if (dateDifferenceFromYTD <= 7) {
      baseChartData = chartData.chart[chartTimeRange.WEEKLY];
    } else if (dateDifferenceFromYTD > 7 && dateDifferenceFromYTD <= 30) {
      baseChartData = chartData.chart[chartTimeRange.MONTHLY];
    } else if (dateDifferenceFromYTD > 30 && dateDifferenceFromYTD <= 90) {
      baseChartData = chartData.chart[chartTimeRange.QUARTERLY];
    } else if (dateDifferenceFromYTD > 90) {
      baseChartData = chartData.chart[chartTimeRange.YEARLY];
    }
    if (lastYearEndData && lastYearEndData.date) {
      // find index of immediate date greater than or equal to ytd start date
      const ytdActualStartDateIndex = baseChartData.data.findIndex(
        chartData => new Date(chartData.date) > ytdStartDate
      );
      ytdCustodianChartData = ytdActualStartDateIndex !== -1 ? baseChartData.data.slice(ytdActualStartDateIndex) : [];
      if (
        lastYearEndData.value !== null &&
        ytdCustodianChartData.length &&
        new Date(ytdCustodianChartData[0].date).toDateString() !== new Date(ytdStartDate).toDateString()
      ) {
        lastYearEndData.date = ytdStartDate;
        ytdCustodianChartData.unshift(lastYearEndData);
      }
    } else {
      ytdCustodianChartData = baseChartData.data;
    }
    return {
      ...baseChartData,
      data: ytdCustodianChartData
    };
  }
};

export const filterChartDataBasedOnPortfolioStartDate = chartData => {
  for (let i = 1; i < timeRanges.length; i++) {
    if (chartData.chart[timeRanges[i]].data.length < 7) {
      for (let j = i; j < timeRanges.length; j++) {
        chartData.chart[timeRanges[j]].data = chartData.chart[timeRanges[i - 1]].data;
      }
    }
  }
  return chartData;
};

export const getSymbolForTickerUsingShortName = shortName => {
  const ticker = getTickerUsingShortName(shortName);

  try {
    if (ticker && ticker.symbol.length > 0) {
      return ticker.symbol[0];
    }
  } catch (e) {
    captureError(`No ticker symbol for ${shortName}`);
  }

  return shortName;
};

export const isCryptoCurrency = currency => {
  const ticker = getTickerUsingShortName(currency);
  return ticker && ticker.type === tickerTypes.CRYPTO;
};

export const shortFormatNumberWithCurrency = (
  number,
  currencyCode,
  abbreviate = false,
  includeCurrencySymbol = false,
  includePositiveSign = false,
  alwaysShowDecimal = false,
  showZeroAsText = false,
  showCurrencyAsSymbol = true,
  compactWidth = false
) => {
  var currencySymbol = showCurrencyAsSymbol === false ? currencyCode : getSymbolForTickerUsingShortName(currencyCode);

  if (currencySymbol === currencyCode) {
    currencySymbol = shortenTickerIfNeeded(currencySymbol) + " ";
  }

  if (number === null || number === undefined) {
    return includeCurrencySymbol ? currencySymbol + 0 : 0;
  }

  if (userMaskAllValuesSelector(store.getState()) === true) {
    return includeCurrencySymbol ? currencySymbol + "XXXX" : "XXXXXX";
  }

  var localizedNumber = parseFloat(number);

  if (localizedNumber === 0 && showZeroAsText === true) {
    return includeCurrencySymbol ? currencySymbol + "Zero" : "Zero";
  }

  if (isNaN(localizedNumber)) {
    return includeCurrencySymbol ? currencySymbol + number : number;
  }

  var formattedNumberString = includeCurrencySymbol ? currencySymbol + 0 : 0;
  const isNegative = localizedNumber < 0;
  const minFractionDigits = alwaysShowDecimal === true ? 2 : undefined;

  if (compactWidth && includeCurrencySymbol && currencySymbol.length > 1) {
    const numDigitsBeforeDecimal = Math.floor(Math.log10(Math.abs(localizedNumber))) + 1;
    localizedNumber -= localizedNumber % Math.pow(10, Math.max(numDigitsBeforeDecimal - 8 + currencySymbol.length, 0));
  }

  if (SIMILAR_FORMAT_CURRENCIES.get("INR").includes(currencyCode)) {
    if (Math.abs(localizedNumber) >= 10000000) {
      formattedNumberString =
        parseFloat((localizedNumber / 10000000).toFixed(2)).toLocaleString(undefined, {
          minimumFractionDigits: minFractionDigits
        }) + (abbreviate === false ? " Crore" : "Cr");
    } else if (Math.abs(localizedNumber) >= 100000) {
      formattedNumberString =
        parseFloat((localizedNumber / 100000).toFixed(2)).toLocaleString(undefined, {
          minimumFractionDigits: minFractionDigits
        }) + (abbreviate === false ? " Lakh" : "L");
    } else {
      formattedNumberString = `${
        localizedNumber > 0 && localizedNumber < 1
          ? localizedNumber.toFixed(2)
          : Math.kuberaFloor(localizedNumber).toLocaleString()
      }`;
    }
  } else {
    if (Math.abs(localizedNumber) >= 1000000000000) {
      let currencyString = abbreviate === false ? " Trillion" : "T";
      if (SIMILAR_FORMAT_CURRENCIES.get("COP").includes(currencyCode)) {
        currencyString = abbreviate === false ? " Billion" : "B";
      }
      formattedNumberString =
        formatNumberWithKuberaNumberFormatSettings(parseFloat((localizedNumber / 1000000000000).toFixed(3)), {
          minimumFractionDigits: minFractionDigits
        }) + currencyString;
    } else if (
      Math.abs(localizedNumber) >= 1000000000 &&
      !SIMILAR_FORMAT_CURRENCIES.get("COP").includes(currencyCode)
    ) {
      formattedNumberString =
        formatNumberWithKuberaNumberFormatSettings(parseFloat((localizedNumber / 1000000000).toFixed(3)), {
          minimumFractionDigits: minFractionDigits
        }) + (abbreviate === false ? " Billion" : "B");
    } else if (Math.abs(localizedNumber) >= 1000000) {
      let decimalDigits = 3;
      if (Math.abs(localizedNumber) >= 1000000000) {
        const logValue = Math.log10(Math.abs(number));
        decimalDigits = 11 - Math.floor(logValue);
      }
      formattedNumberString =
        formatNumberWithKuberaNumberFormatSettings(parseFloat((localizedNumber / 1000000).toFixed(decimalDigits)), {
          minimumFractionDigits: minFractionDigits
        }) + (abbreviate === false ? " Million" : "M");
    } else {
      if (isCryptoCurrency(currencyCode)) {
        const numDigitsBeforeDecimal = Math.floor(Math.log10(Math.abs(localizedNumber))) + 1;
        let maximumFractionDigits = 4;
        if (numDigitsBeforeDecimal > 2) {
          maximumFractionDigits = Math.max(0, 7 - numDigitsBeforeDecimal);
        }

        formattedNumberString = formatNumberWithKuberaNumberFormatSettings(localizedNumber, {
          maximumFractionDigits
        });
      } else {
        formattedNumberString = `${
          localizedNumber > 0 && localizedNumber < 1
            ? localizedNumber.toFixed(2)
            : formatNumberWithKuberaNumberFormatSettings(Math.kuberaFloor(localizedNumber))
        }`;
      }
    }
  }

  if (isNegative === true) {
    return includeCurrencySymbol
      ? "-" + currencySymbol + formattedNumberString.replace("-", "")
      : formattedNumberString;
  } else {
    if (includeCurrencySymbol) {
      return currencySymbol + formattedNumberString;
    } else {
      return includePositiveSign ? `+${formattedNumberString}` : formattedNumberString;
    }
  }
};

export function getPVSTRate(rateStr, targetShortName) {
  if (!rateStr) return 0;
  const parsedRateDetails = JSON.parse(rateStr);
  let price = parsedRateDetails?.u || 0;
  if (price) {
    const rateTicker = getTickerUsingId(parsedRateDetails.t);
    price = getExchangeRate(rateTicker.shortName, targetShortName) * parsedRateDetails.p || 0;
  }
  return price;
}

export const updateCustodianDetailsAction = updatedDetails => ({
  type: UPDATE_CUSTODIAN_DETAILS,
  updatedDetails
});

export const shortenTickerIfNeeded = ticker => {
  let final = ticker;
  const tickerVal = getTickerUsingShortName(ticker);
  if (tickerVal && tickerVal.code !== "UNKNOWN") {
    final = tickerVal.code;
  }
  return final === "UNKNOWN" ? "????" : final.length > 5 ? "*" + final.slice(final.length - 4, final.length) : final;
};

export const getCurrentDateDifferenceFromYTD = () => {
  const currentDate = new Date();
  const ytdStartDate = getYTDStartDate();
  return Math.ceil(Math.abs(currentDate - ytdStartDate) / (1000 * 60 * 60 * 24));
};

export const getYTDIndexInTimeRangeArray = () => {
  const dateDifferenceFromYTD = getCurrentDateDifferenceFromYTD();
  let ytdIndex;
  if (dateDifferenceFromYTD <= 7) {
    ytdIndex = 1;
  } else if (dateDifferenceFromYTD > 7 && dateDifferenceFromYTD <= 30) {
    ytdIndex = 2;
  } else if (dateDifferenceFromYTD > 30 && dateDifferenceFromYTD <= 90) {
    ytdIndex = 3;
  } else if (dateDifferenceFromYTD > 90 && dateDifferenceFromYTD <= 365) {
    ytdIndex = 4;
  } else {
    ytdIndex = 5;
  }
  return ytdIndex;
};

export const userMaskAllValuesSelector = state => {
  const siteConfig = siteConfigSelector(state);
  if (siteConfig && siteConfig.mask === 1) {
    return state.auth.shareMaskValue;
  }
  return isMobileDevice ? state.auth.userPreferences.maskAllValuesMobile : state.auth.userPreferences.maskAllValues;
};

export const fundScheduleTypes = {
  CAPITAL_CALL: "capitalcall",
  DISTRIBUTION: "distribution"
};

export const fundScheduleDurations = {
  OVERDUE: "overdue",
  IN_30_DAYS: "in30days",
  IN_90_DAYS: "in90days",
  MORE_THAN_90_DAYS: "morethan90days",
  UNSCHEDULED: "unscheduled"
};

export const getFundScheduleTitle = duration => {
  switch (duration) {
    case fundScheduleDurations.OVERDUE:
      return i18n.t("fundScheduleDuration.overdue");
    case fundScheduleDurations.IN_30_DAYS:
      return i18n.t("fundScheduleDuration.30days");
    case fundScheduleDurations.IN_90_DAYS:
      return i18n.t("fundScheduleDuration.90days");
    case fundScheduleDurations.MORE_THAN_90_DAYS:
      return i18n.t("fundScheduleDuration.moreThan90Days");
    case fundScheduleDurations.UNSCHEDULED:
      return i18n.t("fundScheduleDuration.unscheduled");
    default:
      "";
  }
};

export const isWlEmailPlaceholder = email => {
  return email.indexOf(WL_PLACEHOLDER_EMAIL) !== -1 && email.endsWith("@kubera.com");
};

export const assetClassesListSelector = createSelector(
  [state => state.tickers.tickerData],
  tickerData => tickerData?.assetClasses || []
);

export const getAssetClassForCustodianFromFundamentalData = custodian => {
  const assetClassesList = assetClassesListSelector(store.getState());
  const tickerSubType = custodian?.vTSTp;
  const tickerType = custodian?.vTTp;
  if (custodian.tp === 2) {
    return assetClassesList[9].name;
  } else if (tickerType !== "fiat") {
    if (tickerSubType === "pm" || tickerSubType === "spm") {
      return assetClassesList[5].name;
    } else if (tickerSubType === "sre") {
      return assetClassesList[6].name;
    } else if (tickerSubType === "scr" || tickerType === "crypto") {
      return assetClassesList[4].name;
    } else if (tickerType === "stock") {
      return assetClassesList[1].name;
    } else if (tickerType === "bond") {
      return assetClassesList[2].name;
    } else if (tickerType === "derivative") {
      return assetClassesList[7].name;
    } else if (tickerType === "fund" || tickerType === "index") {
      if (custodian?.vTCc === "US") {
        // US funds
        return assetClassesList[3].name;
      } else {
        // non US funds
        if (custodian.acId) {
          const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.acId);
          return assetClass.name;
        }
        return assetClassesList[3].name;
      }
    }
  } else {
    // handle for fiat
    if (custodian.vTId === PVST_VALUE_TICKER_ID) {
      if (custodian.acId) {
        const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.acId);
        return assetClass.name;
      }
      return assetClassesList[1].name;
    } else if (
      custodian.linkType === 8 ||
      tickerSubType === "cars" ||
      [custodianSubTypes.CAR, custodianSubTypes.CARS].includes(custodian.sTp)
    ) {
      return assetClassesList[8].name;
    } else if (
      custodian.linkType === 7 ||
      tickerSubType === "domains" ||
      [custodianSubTypes.DOMAIN, custodianSubTypes.DOMAINS].includes(custodian.sTp)
    ) {
      return assetClassesList[10].name;
    } else if (custodian.linkType === 6 || custodian.sTp === custodianSubTypes.HOME) {
      return assetClassesList[6].name;
    } else if (tickerSubType === "pm" || tickerSubType === "spm") {
      return assetClassesList[5].name;
    } else {
      if (custodian.acId) {
        const assetClass = assetClassesList.find(assetClass => assetClass.id === custodian.acId);
        return assetClass.name;
      }
      return assetClassesList[0].name;
    }
  }
};
